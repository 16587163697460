document.addEventListener('DOMContentLoaded', function () {
  if (document.body.classList.contains('single-product')) {
    const mainImages = document.querySelectorAll(
      '.woocommerce-product-gallery__wrapper > .woocommerce-product-gallery__image'
    )
    const thumbnails = document.querySelectorAll('.flex-control-thumbs li')

    thumbnails.forEach((thumbnail, index) => {
      thumbnail.addEventListener('click', function (e) {
        e.preventDefault()

        mainImages.forEach((mainImage) => {
          mainImage.style.display = 'none'
        })

        mainImages[index].style.display = 'block'
      })
    })
  }
})
