document.addEventListener('DOMContentLoaded', () => {
  const content = document.getElementById('scrollGroupedTable')
  const scrollbar = document.querySelector('.custom-scrollbar')
  const scrollThumb = document.querySelector('.scrollbar-thumb')

  if (!content || !scrollbar || !scrollThumb) return

  // Logic for updating scrollbar position
  const updateScrollbarPosition = () => {
    const rect = content.getBoundingClientRect()
    const parentRect = content.parentElement.getBoundingClientRect()
    const viewportHeight = window.innerHeight

    const parentTop = parentRect.top
    const parentBottom = parentRect.bottom
    const scrollbarHeight = 8

    const scrollbarTopPosition = viewportHeight - scrollbarHeight
    const isAboveParent = parentTop > scrollbarTopPosition
    const isBelowParent = parentBottom < scrollbarTopPosition

    // First check if content is scrollable
    if (content.scrollWidth <= content.clientWidth) {
      scrollbar.classList.remove('visible')
      return
    }

    if (isAboveParent) {
      scrollbar.classList.remove('visible')
    } else if (isBelowParent) {
      // Parent is completely below where scrollbar would be
      scrollbar.style.position = 'absolute'
      scrollbar.style.bottom = '0'
      scrollbar.style.top = 'auto'
      scrollbar.style.left = '0'
      scrollbar.style.width = '100%'
      scrollbar.classList.add('visible')
    } else if (parentTop > 0 && parentTop < 100) {
      // Parent is getting close to top of viewport
      scrollbar.style.position = 'absolute'
      scrollbar.style.top = '0'
      scrollbar.style.bottom = 'auto'
      scrollbar.style.left = '0'
      scrollbar.style.width = '100%'
      scrollbar.classList.add('visible')
    } else {
      // Normal scroll following within bounds
      scrollbar.style.position = 'fixed'
      scrollbar.style.bottom = '8px'
      scrollbar.style.top = 'auto'
      scrollbar.style.left = `${rect.left}px`
      scrollbar.style.width = `${rect.width}px`
      scrollbar.classList.add('visible')
    }
  }

  // Calculate and set initial thumb width
  const updateThumbWidth = () => {
    const contentWidth = content.scrollWidth
    const visibleWidth = content.clientWidth
    const ratio = visibleWidth / contentWidth
    scrollThumb.style.width = `${ratio * 100}%`
  }

  // Update thumb position when content is scrolled
  content.addEventListener('scroll', () => {
    const scrollRatio = content.scrollLeft / (content.scrollWidth - content.clientWidth)
    const thumbPosition = scrollRatio * (content.clientWidth - scrollThumb.offsetWidth)
    scrollThumb.style.left = `${thumbPosition}px`
  })

  // Make the thumb draggable
  let isDragging = false
  let startX = 0
  let scrollStartLeft = 0

  // Mouse events
  scrollThumb.addEventListener('mousedown', (e) => {
    isDragging = true
    startX = e.clientX
    scrollStartLeft = content.scrollLeft
    e.preventDefault()
  })

  document.addEventListener('mousemove', (e) => {
    if (!isDragging) return

    const delta = e.clientX - startX
    const scrollRatio = delta / (content.clientWidth - scrollThumb.offsetWidth)
    content.scrollLeft = scrollStartLeft + scrollRatio * (content.scrollWidth - content.clientWidth)
  })

  document.addEventListener('mouseup', () => {
    isDragging = false
  })

  // Touch events
  scrollThumb.addEventListener('touchstart', (e) => {
    isDragging = true
    startX = e.touches[0].clientX
    scrollStartLeft = content.scrollLeft
    e.preventDefault()
  })

  document.addEventListener('touchmove', (e) => {
    if (!isDragging) return

    const delta = e.touches[0].clientX - startX
    const scrollRatio = delta / (content.clientWidth - scrollThumb.offsetWidth)
    content.scrollLeft = scrollStartLeft + scrollRatio * (content.scrollWidth - content.clientWidth)
    e.preventDefault()
  })

  document.addEventListener('touchend', () => {
    isDragging = false
  })

  // Initial setup
  updateThumbWidth()
  updateScrollbarPosition()

  window.addEventListener('scroll', updateScrollbarPosition)
  window.addEventListener('resize', () => {
    updateThumbWidth()
    updateScrollbarPosition()
  })

  // Cleanup
  return () => {
    window.removeEventListener('scroll', updateScrollbarPosition)
    window.removeEventListener('resize', updateScrollbarPosition)
  }
})
